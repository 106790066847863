import loadable from '@loadable/component';
import ROUTE_PATHS from 'config/routePaths';
import SGJuniorBakeOff2024 from 'containers/SGJuniorBakeOff2024';
import { useRealtimeEvents } from 'hooks/pusher';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

const DownloadBanner = loadable(() => import('components/DownloadBanner'));
const TopBar = loadable(() => import('components/TopBar'));
const ChallengeContainer = loadable(() => import('containers/Challenge'));
const FeedChallengesContainer = loadable(() => import('containers/Challenges'));
const JuniorIdol = loadable(() => import('containers/JuniorIdol'));
const TeenChefIdol = loadable(() => import('containers/TeenChefIdol'));
const RamadanLP = loadable(() => import('containers/RamadanLP'));
const RamadanLP2023 = loadable(() => import('containers/RamadanLP2023'));
const HealthierLp = loadable(() => import('containers/HealthierLP'));
const IndoJuniorChef = loadable(() => import('containers/IndoJuniorChef'));
const JuniorChef2 = loadable(() => import('containers/JuniorChef2'));
const CNY2022 = loadable(() => import('containers/CNY2022'));
const AliceNero = loadable(() => import('containers/AliceNero'));
const AyamBrandCookbook = loadable(() => import('containers/AyamBrandCookbook'));
const PrivacyPolicy = loadable(() => import('containers/PrivacyPolicy'));
const RamadanMealPlan = loadable(() => import('containers/RamadanMealPlan'));
const FestiveCookbook = loadable(() => import('containers/FestiveCookbook'));
const CNYFestiveCookbook = loadable(() => import('containers/CNYFestiveCookbook'));
const BelcubeCookbook = loadable(() => import('containers/BelcubeCookbook'));
const KrimKafeCookbook = loadable(() => import('containers/KrimKafeCookbook'));
const CNY2023 = loadable(() => import('containers/CNY2023'));
const KidBaker = loadable(() => import('containers/KidBaker'));
const SugarFree = loadable(() => import('containers/SugarFree'));
const DoleFruitPops = loadable(() => import('containers/DoleFruitPops'));
const DOLE2023 = loadable(() => import('containers/DOLE2023'));
const DoleMYS2023 = loadable(() => import('containers/DoleMYS2023'));
const AlceNeroWorkshop = loadable(() => import('containers/AlceNero2023'));
const FamilyDay2023 = loadable(() => import('containers/FamilyDay2023'));
const DelMonteCNYContest = loadable(() => import('containers/DelMonteCNYContest'));
const DoleBuyAndWin2024 = loadable(() => import('containers/DoleBuyAndWin2024'));
const BackToSchoolLaughingCow = loadable(() => import('containers/BackToSchoolLaughingCow'));
const DoleICanDoIt = loadable(() => import('containers/DoleICanDoIt'));
const ICanDoleItCookbook = loadable(() => import('containers/ICanDoleItCookbook'));
const DelMonteSchoolBootcamp = loadable(() => import('containers/DelMonteSchoolBootcamp'));
const IndoJuniorChef2024 = loadable(() => import('containers/IndoJuniorChef2024'));
const CNYFundraising = loadable(() => import('containers/CNYFundraising'));
const NotFound = loadable(() => import('containers/NotFound'));
const ProfileContainer = loadable(() => import('containers/Profile'));
const RecipeContainer = loadable(() => import('containers/RecipePage'));
const FeedRecipesContainer = loadable(() => import('containers/Recipes'));
const SearchContainer = loadable(() => import('containers/Search'));
const SgIdolLandingPage = loadable(() => import('containers/HomeChef2022'));
const SgHomeChefLp = loadable(() => import('containers/SgIdolLandingPage'));
const FeedStoriesContainer = loadable(() => import('containers/Stories'));
const StoryContainer = loadable(() => import('containers/StoryPage'));
const BottomBanner = loadable(() => import('components/SmartBanners/BottomBanner'));
const SmartPopup = loadable(() => import('components/SmartBanners/SmartPopup'));
const CollectionsContainer = loadable(() => import('containers/Collections'));
const LivestreamsContainer = loadable(() => import('containers/Livestreams'));
const LivestreamContainer = loadable(() => import('containers/Livestream'));
const EventsContainer = loadable(() => import('containers/Events'));
const GroupChatContainer = loadable(() => import('containers/GroupChat'));

const landingPages = [
    ROUTE_PATHS.AYAM_COOKBOOK,
    ROUTE_PATHS.INDO_RAMADA_2023,
    ROUTE_PATHS.ALICE_NERO,
    ROUTE_PATHS.SGLP_IDOL,
    ROUTE_PATHS.SGLP,
    ROUTE_PATHS.SG_HOME_CHEF_2022,
    ROUTE_PATHS.SGJUNIOR,
    ROUTE_PATHS.SGTEENS,
    ROUTE_PATHS.CNY_2022,
    ROUTE_PATHS.CNY_2023,
    ROUTE_PATHS.CNY_2023_JOINED,
    ROUTE_PATHS.DOLE_2023,
    ROUTE_PATHS.DOLE_2023_JOINED,
    ROUTE_PATHS.CNY_FUNDRAISING,
    ROUTE_PATHS.INDO_RAMADA,
    ROUTE_PATHS.HEALTHEIRLP,
    ROUTE_PATHS.INDO_JUNIOR,
    ROUTE_PATHS.INDO_JUNIOR_2,
    ROUTE_PATHS.KID_BAKER,
    ROUTE_PATHS.KID_BAKER_JOINED,
    ROUTE_PATHS.SUGAR_FREE,
    ROUTE_PATHS.SUGAR_FREE_JOINED,
    ROUTE_PATHS.DOLE_MYS,
    ROUTE_PATHS.DOLE_MYS_JOINED,
    ROUTE_PATHS.ALCE_NERO_WORKSHOP,
    ROUTE_PATHS.ALCE_NERO_WORKSHOP_JOINED,
    ROUTE_PATHS.FAMILY_DAY_2023,
    ROUTE_PATHS.FAMILY_DAY_2023_JOINED,
    ROUTE_PATHS.FESTIVE_COOKBOOK,
    ROUTE_PATHS.CNY_FESTIVE_COOKBOOK,
    ROUTE_PATHS.DEL_MONTE_CNY_CONTEST,
    ROUTE_PATHS.DEL_MONTE_CNY_CONTEST_JOINED,
    ROUTE_PATHS.BELCUBE,
    ROUTE_PATHS.RAMADAN_MEAL_PLAN,
    ROUTE_PATHS.KRIMKAFE,
    ROUTE_PATHS.DOLE_FRUIT_POPS,
    ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP,
    ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP_JOINED,
    ROUTE_PATHS.BACK_TO_SCHOOL,
    ROUTE_PATHS.BACK_TO_SCHOOL_JOINED,
    ROUTE_PATHS.DOLE_I_CAN_DO_IT,
    ROUTE_PATHS.SG_JUNIOR_BAKE_OFF,
    ROUTE_PATHS.SG_JUNIOR_BAKE_OFF_JOINED,
    ROUTE_PATHS.JUNIOR_CHEF_2024,
    ROUTE_PATHS.JUNIOR_CHEF_2024_JOINED
];

function Routes() {
    useRealtimeEvents();

    const [showBanner, setShowBanner] = useState(false);
    const [showMobileBanner, setMobileBanner] = useState(true);

    return (
        <div className='position-relative root-inner-wrapper'>
            <TopBar showBanner={() => setShowBanner(true)} />
            <Switch>
                <Route path={ROUTE_PATHS.DEFAULT} exact={true} component={FeedStoriesContainer} />
                <Route path={ROUTE_PATHS.PRIVACY_POLICY} exact={true} component={PrivacyPolicy} />
                <Route path={ROUTE_PATHS.SGLP_IDOL} exact={true} component={() => <Redirect to={ROUTE_PATHS.SGLP} />} />
                <Route path={ROUTE_PATHS.SGLP} exact={true} component={SgHomeChefLp} />
                <Route path={ROUTE_PATHS.SG_HOME_CHEF_2022} exact={true} component={SgIdolLandingPage} />
                <Route path={ROUTE_PATHS.SGJUNIOR} exact={true} component={JuniorIdol} />
                <Route path={ROUTE_PATHS.SGTEENS} exact={true} component={TeenChefIdol} />
                <Route path={ROUTE_PATHS.CNY_2022} exact={true} component={CNY2022} />
                <Route path={ROUTE_PATHS.CNY_2023} exact={true} component={CNY2023} />
                <Route path={ROUTE_PATHS.CNY_2023_JOINED} exact={true} component={CNY2023} />
                <Route path={ROUTE_PATHS.KID_BAKER} exact={true} component={KidBaker} />
                <Route path={ROUTE_PATHS.KID_BAKER_JOINED} exact={true} component={KidBaker} />
                <Route path={ROUTE_PATHS.SUGAR_FREE} exact={true} component={SugarFree} />
                <Route path={ROUTE_PATHS.ALCE_NERO_WORKSHOP} exact={true} component={AlceNeroWorkshop} />
                <Route path={ROUTE_PATHS.ALCE_NERO_WORKSHOP_JOINED} exact={true} component={AlceNeroWorkshop} />
                <Route path={ROUTE_PATHS.FAMILY_DAY_2023} exact={true} component={FamilyDay2023} />
                <Route path={ROUTE_PATHS.FAMILY_DAY_2023_JOINED} exact={true} component={FamilyDay2023} />
                <Route path={ROUTE_PATHS.DEL_MONTE_CNY_CONTEST} exact={true} component={DelMonteCNYContest} />
                <Route path={ROUTE_PATHS.DEL_MONTE_CNY_CONTEST_JOINED} exact={true} component={DelMonteCNYContest} />
                <Route path={ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP} exact={true} component={DelMonteSchoolBootcamp} />
                <Route path={ROUTE_PATHS.DEL_MONTE_SCHOOL_BOOTCAMP_JOINED} exact={true} component={DelMonteSchoolBootcamp} />
                <Route path={ROUTE_PATHS.BACK_TO_SCHOOL} exact={true} component={BackToSchoolLaughingCow} />
                <Route path={ROUTE_PATHS.BACK_TO_SCHOOL_JOINED} exact={true} component={BackToSchoolLaughingCow} />
                <Route path={ROUTE_PATHS.SUGAR_FREE_JOINED} exact={true} component={SugarFree} />
                <Route path={ROUTE_PATHS.DOLE_2023} exact={true} component={DOLE2023} />
                <Route path={ROUTE_PATHS.DOLE_2023_JOINED} exact={true} component={DOLE2023} />
                <Route path={ROUTE_PATHS.DOLE_MYS} exact={true} component={DoleMYS2023} />
                <Route path={ROUTE_PATHS.DOLE_MYS_JOINED} exact={true} component={DoleMYS2023} />
                <Route path={ROUTE_PATHS.DOLE_BUY_AND_WIN_2024} exact={true} component={DoleBuyAndWin2024} />
                <Route path={ROUTE_PATHS.DOLE_BUY_AND_WIN_2024_JOINED} exact={true} component={DoleBuyAndWin2024} />
                <Route path={ROUTE_PATHS.SG_JUNIOR_BAKE_OFF} exact={true} component={SGJuniorBakeOff2024} />
                <Route path={ROUTE_PATHS.SG_JUNIOR_BAKE_OFF_JOINED} exact={true} component={SGJuniorBakeOff2024} />
                <Route path={ROUTE_PATHS.JUNIOR_CHEF_2024} exact={true} component={IndoJuniorChef2024} />
                <Route path={ROUTE_PATHS.JUNIOR_CHEF_2024_JOINED} exact={true} component={IndoJuniorChef2024} />
                <Route path={ROUTE_PATHS.DOLE_I_CAN_DO_IT} exact={true} component={DoleICanDoIt} />
                <Route path={ROUTE_PATHS.CNY_FUNDRAISING} exact={true} component={CNYFundraising} />
                <Route path={ROUTE_PATHS.INDO_RAMADA} exact={true} component={RamadanLP} />
                <Route path={ROUTE_PATHS.INDO_RAMADA_2023} exact={true} component={RamadanLP2023} />
                <Route path={ROUTE_PATHS.ALICE_NERO} exact={true} component={AliceNero} />
                <Route path={ROUTE_PATHS.AYAM_COOKBOOK} exact={true} component={AyamBrandCookbook} />
                <Route path={ROUTE_PATHS.FESTIVE_COOKBOOK} exact={true} component={FestiveCookbook} />
                <Route path={ROUTE_PATHS.RAMADAN_MEAL_PLAN} exact={true} component={RamadanMealPlan} />
                <Route path={ROUTE_PATHS.CNY_FESTIVE_COOKBOOK} exact={true} component={CNYFestiveCookbook} />
                <Route path={ROUTE_PATHS.I_CAN_DO_IT_COOKBOOK} exact={true} component={ICanDoleItCookbook} />
                <Route path={ROUTE_PATHS.DOLE_FRUIT_POPS} exact={true} component={DoleFruitPops} />
                <Route path={ROUTE_PATHS.BELCUBE} exact={true} component={BelcubeCookbook} />
                <Route path={ROUTE_PATHS.KRIMKAFE} exact={true} component={KrimKafeCookbook} />
                <Route path={ROUTE_PATHS.HEALTHEIRLP} exact={true} component={HealthierLp} />
                <Route path={ROUTE_PATHS.INDO_JUNIOR} exact={true} component={IndoJuniorChef} />
                <Route path={ROUTE_PATHS.INDO_JUNIOR_2} exact={true} component={JuniorChef2} />
                <Route path={ROUTE_PATHS.SEARCH} exact={true} component={() => <Redirect to='/search/all' />} />
                <Route path={ROUTE_PATHS.SEARCH_FILTER} exact={false} component={SearchContainer} />
                <Route path={ROUTE_PATHS.STORIES} exact={true} component={FeedStoriesContainer} />
                <Route path={ROUTE_PATHS.RECIPES} exact={false} component={FeedRecipesContainer} />
                <Route path={ROUTE_PATHS.LIVESTREAMS} exact={true} component={LivestreamsContainer} />
                <Route path={ROUTE_PATHS.LIVESTREAM_AUDIENCE} exact={false} component={LivestreamContainer} />
                <Route path={ROUTE_PATHS.CHALLENGES_CATEGORY} exact={false} component={FeedChallengesContainer} />
                <Route path={ROUTE_PATHS.CHALLENGES} exact={false} component={FeedChallengesContainer} />
                <Route path={ROUTE_PATHS.STORY_PAGE} exact={true} component={StoryContainer} />
                <Route path={ROUTE_PATHS.CHALLENGE} exact={true} component={ChallengeContainer} />
                <Route path={ROUTE_PATHS.RECIPE} exact={true} component={RecipeContainer} />
                <Route path={ROUTE_PATHS.PROFILE} exact={true} component={ProfileContainer} />
                <Route path={ROUTE_PATHS.TOP_COLLECTIONS} exact={true} component={CollectionsContainer} />
                <Route path={ROUTE_PATHS.COLLECTION} exact={true} component={CollectionsContainer} />
                <Route path={ROUTE_PATHS.EVENTS} exact={true} component={EventsContainer} />
                <Route path={ROUTE_PATHS.EVENT} exact={true} component={EventsContainer} />
                <Route path={ROUTE_PATHS.GROUPS} exact={true} component={GroupChatContainer} />
                <Route path={ROUTE_PATHS.GROUP} exact={true} component={GroupChatContainer} />
                <Route component={NotFound} />
                <Route path='/not-found' component={NotFound} />
            </Switch>
            {showBanner && <DownloadBanner onClose={() => setShowBanner(false)} />}
            <BottomBanner landingPages={landingPages} />
            <SmartPopup isVisible={showMobileBanner} landingPages={landingPages} onClose={() => setMobileBanner(false)} />
        </div>
    );
}

export default Routes;
